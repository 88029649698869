import React, { Component } from "react";


class EmptyWarn extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: {}
        };
    };

    render() {
        return (
            <div className={`empty-warn${this.props.backgroundColor}`}>
                <img width="24px" height="24px" alt="warn" src={this.props.icon}></img>
                <span>{this.props.text}</span>
            </div>
        );
    }
}

export default EmptyWarn;