import { Component } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import HomeScreen from "./routes/HomeScreen";
import TrackScreen from "./routes/TrackScreen";

class App extends Component {
  constructor(props) {
      super(props);

      this.state = {};
  };
  render() {
      return (
        <div className="app">
        <Router>
          <Switch>
            <Route exact path="/">
              <HomeScreen />
            </Route>
            <Route exact path="/:id">
              <TrackScreen />
            </Route>
          </Switch>
        </Router>
      </div>
      );
  }
}

export default App;