import React, { Component } from "react";
import Header from "../components/Header";
import EmptyMap from "../components/EmptyMap";
import PoweredBy from "../assets/img/powered-by.svg";
import PoweredByWhite from "../assets/img/powered-by-white.svg";
import EmptyWarn from "../components/EmptyWarn";
import HistoryWhite from "../assets/img/history-white.svg";
import HistoryBlack from "../assets/img/history-black.svg";

class HomeScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      darkMode: null,
      error: "Você não possui entregas em andamento"
    };
  };
  componentDidMount() {
    if (localStorage.getItem('last-error')) {
      this.setState({
        error: localStorage.getItem('last-error')
      });
      return localStorage.removeItem('last-error');
    }
  };
  switchMode = () => {
    this.state.darkMode ? this.setState({ darkMode: false }) : this.setState({ darkMode: true });
  };
  render() {
    return (
      <div className="trackscreen">
        <Header
          poweredImg={this.state.darkMode ? PoweredByWhite : PoweredBy}
          dark={this.state.darkMode === null ? "" : this.state.darkMode ? " dark" : " white"}
          whiteText={this.state.darkMode === null ? "" : this.state.darkMode ? " white" : " dark"}
          switchMode={() => this.switchMode()}
          modeText={this.state.darkMode ? "MODO ESCURO" : "MODO CLARO"}
        />
        <EmptyWarn
          text={this.state.error}
          backgroundColor={this.state.darkMode === null ? "" : this.state.darkMode ? " yellow" : " black"}
          icon={this.state.darkMode ? HistoryBlack : HistoryWhite}
        />
        <div className={this.state.darkMode ? "map-displayed" : "map-hidden"}>
          <EmptyMap
            url="https://api.maptiler.com/maps/ch-swisstopo-lbm-dark/{z}/{x}/{y}.png?key=WwGnP47aLGyCTtKvrdQd"
          />
        </div>
        <div className={this.state.darkMode ? "map-hidden" : "map-displayed"}>
          <EmptyMap
            url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
          />
        </div>
        <div className="homescreen">
          Home
          {this.state.error ? "O pedido não está em andamento." : ""}
        </div>
        <img src={this.state.darkMode ? PoweredByWhite : PoweredBy} className="box-logo-mobile" width="145px" height="25px" alt="Logo Box Delivery"></img>
      </div>
    );
  }
};

export default HomeScreen;