import React, { Component } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";

class EmptyMap extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    };

    render() {
        return (
            <MapContainer
                center={[-23.55789081450437, -46.661591646102586]}
                zoom={16}
                scrollWheelZoom={false}
                className="map-empty"
            >
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url={this.props.url}
                />
            </MapContainer>
        );
    }
}

export default EmptyMap;