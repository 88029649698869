import React from "react";
import { MapContainer, TileLayer, Marker, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import Estabelecimento from "../assets/img/estabelecimento.png";
import Moto from "../assets/img/in_delivery.png";
import { Icon } from 'leaflet';
import { useState, useEffect, useCallback, useRef, forwardRef } from "react";
import "leaflet-rotatedmarker";

const ChangeView = ({ center, zoom }) => {
    const map = useMap();
    map.setView(center, zoom);
    return null;
}
const RotatedMarker = forwardRef(({ children, ...props }, forwardRef) => {
    const markerRef = useRef();

    const { rotationAngle, rotationOrigin } = props;
    useEffect(() => {
        const marker = markerRef.current;
        if (marker) {
            marker.setRotationAngle(rotationAngle);
            marker.setRotationOrigin(rotationOrigin);
        }
    }, [rotationAngle, rotationOrigin]);

    return (
        <Marker
            ref={(ref) => {
                markerRef.current = ref;
                if (forwardRef) {
                    forwardRef.current = ref;
                }
            }}
            {...props}
        >
            {children}
        </Marker>
    );
});

const LeafletMap = (props) => {
    //const [heading, setHeading] = useState(props.data.courier_direction);
    const destLatLng = [props.data.destination_lat, props.data.destination_lng];
    const originLatLng = [props.data.origin_lat, props.data.origin_lng];
    // const rotation = useCallback(() => {
    //     setHeading((heading) => heading + 90);
    // }, []);

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         rotation();
    //     }, 10000);
    //     return () => {
    //         clearInterval(interval);
    //     };
    // }, [rotation]);
    return (
        <MapContainer
            center={props.mapCenter}
            zoom={16}
            scrollWheelZoom={true}
            className="map"
        >
            <ChangeView center={props.mapCenter} zoom={16} />
            <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url={props.url}
            />
            {props.data &&
                <>
                    <Marker
                        position={destLatLng}
                        icon={new Icon({ iconUrl: props.clientImage, iconSize: [48, 65], iconAnchor: [24, 65] })}
                    />
                    <Marker
                        position={originLatLng}
                        icon={new Icon({ iconUrl: Estabelecimento, iconSize: [48, 65], iconAnchor: [24, 65] })}
                    />
                    <Marker
                        position={props.deliveryManCoordinates ? props.deliveryManCoordinates : originLatLng}
                        icon={new Icon({ iconUrl: Moto, iconSize: props.deliveryManCoordinates ? [48, 65] : [0, 0], iconAnchor: [24, 65] })}
                    />
                    <RotatedMarker
                        position={props.deliveryManCoordinates ? props.deliveryManCoordinates : originLatLng}
                        //rotationAngle={heading}
                        rotationOrigin="center"
                        icon={new Icon({ iconUrl: Moto, iconSize: props.deliveryManCoordinates ? [48, 65] : [0, 0], iconAnchor: [24, 65] })}
                        //icon={new Icon({ iconUrl: Moto, iconSize: props.deliveryManCoordinates ? [20, 48] : [0, 0], iconAnchor: [10, 48] })}
                    />
                </>
            }
        </MapContainer>
    )
}

export default LeafletMap;