import React, { Component } from "react";
import Header from "../components/Header";
import Card from "../components/Card";
import LeafletMap from "../components/LeafletMap";
import PoweredBy from "../assets/img/powered-by.svg";
import PoweredByWhite from "../assets/img/powered-by-white.svg";
import axios from 'axios';
import Client from "../assets/img/cliente.png";
import YellowClient from "../assets/img/cliente-yellow.png";
/*global io*/ //

class TrackScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            darkMode: null,
            data: null,
            deliveryManCoordinates: null,
            mapCenter: null,
            cardStatus: "",
            estimatedDelivery: "",
            orderId: ""
        };
    };
    async componentDidMount() {
        const request = this.getOrder();
        request.then(response => {
            console.log(response);
            const socket = io.connect("https://courier-position-ws.delivery.develop.boxdelivery.com.br", {
                query: {
                    delivery: response.data.delivery_id
                }
            });
            socket.on("courier-position-updated", data => {
                console.log(data);
                this.setState({
                    deliveryManCoordinates: [data.data.lat, data.data.lng],
                    mapCenter: [data.data.lat, data.data.lng]
                });
            });
        });
        var urlParams = new URLSearchParams(window.location.search),
            paramP = urlParams.get('p'),
            deBase64 = new Buffer(paramP, 'base64').toString('ascii'),
            estimatedDelivery = JSON.parse(deBase64).estimatedDelivery && JSON.parse(deBase64).estimatedDelivery,
            orderId = JSON.parse(deBase64).orderId && JSON.parse(deBase64).orderId,
            [year, month, day] = estimatedDelivery.split(' ')[0].split('-'),
            [hours, minutes, seconds] = estimatedDelivery.split(' ')[1].split(':'),
            formattedEstimatedDelivery = `${day}/${month} até ${hours}:${minutes}`
        this.setState({ estimatedDelivery: formattedEstimatedDelivery, orderId: orderId })
        setTimeout(() => {
            setInterval(() => {
                this.getOrder();
            }, 20000);
        }, 20000)
    };
    getOrder = async () => {
        const trackId = window.location.pathname.replace('/', '');
        const fetchData = {
            method: "GET",
            url: process.env.REACT_APP_GET_TRACK_ENDPOINT + '/v2/tracker/' + trackId,
            headers: {
                'Authorization': 'token ' + process.env.REACT_APP_GET_TOKEN,
            }
        }
        try {
            const response = await axios(fetchData);
            let orderStatus;
            response.data.status_order === 1 ? orderStatus = "Localizando entregador" :
                response.data.status_order === 2 ? orderStatus = "Aguardando entregador" :
                    response.data.status_order === 3 ? orderStatus = "O entregador chegou no estabelecimento" :
                        response.data.status_order === 4 ? orderStatus = "O pedido saiu para entrega" :
                            response.data.status_order === 5 ? orderStatus = "Seu pedido chegou!" : orderStatus = ""
            this.setState({
                data: response.data,
                cardStatus: orderStatus
            })
            if (this.state.mapCenter === null) {
                this.setState({
                    mapCenter: [response.data.origin_lat, response.data.origin_lng]
                })
            }
            return response;
        } catch (error) {
            if (error.response.data.error) {
                localStorage.setItem('last-error', error.response.data.error);
            } else {
                localStorage.setItem('last-error', "Não foi possível localizar o pedido");
            }
            window.location.replace("/");
            return error;
        }
    }
    switchMode = () => {
        this.state.darkMode ? this.setState({ darkMode: false }) : this.setState({ darkMode: true });
    };
    render() {
        return (
            <div className="trackscreen">
                {this.state.data ?
                    <>
                        <Header
                            poweredImg={this.state.darkMode ? PoweredByWhite : PoweredBy}
                            dark={this.state.darkMode === null ? "" : this.state.darkMode ? " dark" : " white"}
                            whiteText={this.state.darkMode === null ? "" : this.state.darkMode ? " white" : " dark"}
                            switchMode={() => this.switchMode()}
                            modeText={this.state.darkMode ? "MODO ESCURO" : "MODO CLARO"}
                            data={this.state.data}
                        />
                        <Card
                            data={this.state.data}
                            dark={this.state.darkMode === null ? "" : this.state.darkMode ? " dark" : " white"}
                            whiteText={this.state.darkMode === null ? "" : this.state.darkMode ? " white" : " dark"}
                            title={this.state.cardStatus}
                            poweredImg={this.state.darkMode ? PoweredByWhite : PoweredBy}
                            estimatedDelivery={this.state.estimatedDelivery}
                            orderId={this.state.orderId}
                        />
                        <div className={this.state.darkMode ? "map-displayed" : "map-hidden"}>
                            <LeafletMap
                                data={this.state.data}
                                url="https://api.maptiler.com/maps/ch-swisstopo-lbm-dark/{z}/{x}/{y}.png?key=WwGnP47aLGyCTtKvrdQd"
                                clientImage={this.state.darkMode ? YellowClient : Client}
                                deliveryManCoordinates={this.state.deliveryManCoordinates}
                                mapCenter={this.state.mapCenter}
                            />
                        </div>
                        <div className={this.state.darkMode ? "map-hidden" : "map-displayed"}>
                            <LeafletMap
                                data={this.state.data}
                                url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
                                clientImage={this.state.darkMode ? YellowClient : Client}
                                deliveryManCoordinates={this.state.deliveryManCoordinates}
                                mapCenter={this.state.mapCenter}
                            />
                        </div>
                    </>
                    :
                    <></>
                }
            </div>
        );
    }
};

export default TrackScreen;