import YellowButton from "./YellowButton";
import { Component } from "react";

class Card extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  };
  render() {
    return (
      <div className={`card${this.props.dark}`}>
        <img src={this.props.poweredImg} className="box-logo-card" width="145px" height="25px" alt="Logo Box Delivery"></img>
        <h2 className={`card-title${this.props.whiteText}`}>{this.props.title}</h2>
        <p className="card-id">Número do pedido: {this.props.orderId ? this.props.orderId : this.props.data.order_number ? this.props.data.order_number : '-'}</p>
        <div className="card-body">
          {this.props.data.courier_uuid &&
            <>
              {this.props.data.courier_photo &&
                <div className="rounded-image">
                  <img className="deliveryman-img" src={this.props.data.courier_photo} width="44px" height="44px" alt="Foto do entregador" />
                </div>
              }
              <p className="card-info">
                <span className={this.props.whiteText}>Nome do entregador</span>
                <span className={this.props.whiteText}>{this.props.data.courier_name ? this.props.data.courier_name : '-'}</span>
              </p>
            </>
          }
          <p className="card-info margin-auto">
            <span className={this.props.whiteText}>Previsão de entrega</span>
            <span className={this.props.whiteText}>{this.props.estimatedDelivery ? this.props.estimatedDelivery : '-'}</span>
          </p>
        </div>
        {/* <YellowButton /> */}
      </div>
    );
  }
}

export default Card;