import { Component } from "react";

class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    };
    render() {
        return (
            this.props.data ?
                <div className={`header${this.props.dark}`}>
                    <img src={this.props.data.business_photo} width="48px" height="35px" alt="Logo do comércio"></img>
                    <div className="mode-container">
                        <img src={this.props.poweredImg} className="box-logo-header" width="145px" height="25px" alt="Logo Box Delivery"></img>
                        <span className={`mode-text${this.props.whiteText}`}>{this.props.modeText}</span>
                        <label className="switch">
                            <input type="checkbox" checked={this.props.darkMode} onChange={() => this.props.switchMode()} />
                            <span className="slider"></span>
                        </label>
                    </div>
                </div>
                :
                <div className={`header${this.props.dark}`}>
                    <div className="mode-container">
                        <img src={this.props.poweredImg} className="box-logo-header" width="145px" height="25px" alt="Logo Box Delivery"></img>
                        <span className={`mode-text${this.props.whiteText}`}>{this.props.modeText}</span>
                        <label className="switch">
                            <input type="checkbox" checked={this.props.darkMode} onChange={() => this.props.switchMode()} />
                            <span className="slider"></span>
                        </label>
                    </div>
                </div>
        );
    }
}

export default Header;